import { useEffect, useState } from 'react'
import { useCharactersContext } from '../hooks/useCharactersContext'
import { useAuthContext } from '../hooks/useAuthContext'


//Components
import CampaignForm from '../components/CampaignForm';
import CampaignDetails from '../components/CampaignDetails'


const Campaigns = () => {
    const {characters, dispatch} = useCharactersContext()
    const {user} = useAuthContext()
    const [userCampaigns, setUserCampaigns] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [triggerUpdate, setTriggerUpdate] = useState(false) // This state will be used to trigger updates

    useEffect(() => {
        const fetchUserCampaigns = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/campaigns/user`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (response.ok) {
                setUserCampaigns(json)
            }
        }
        
        if (user) {
            fetchUserCampaigns()
        }
        
    }, [dispatch, user, triggerUpdate])

    const handleCampaignCreated = (newCampaign) => {
        // Add the new campaign to the global campaigns list
        setCampaigns(prevCampaigns => [...prevCampaigns, newCampaign])

        // If the campaign belongs to the current user, add it to their campaigns as well
        if (newCampaign.user === user._id) {
            setUserCampaigns(prevUserCampaigns => [...prevUserCampaigns, newCampaign])
        }
    }
    
return (  
        <div>
            <CampaignForm onCampaignCreated={handleCampaignCreated} />
            {userCampaigns.map((campaign) => (
                <CampaignDetails
                    key={campaign._id}
                    campaignId={campaign._id}
                    triggerUpdate={triggerUpdate}
                />
            ))}
        </div>
    )
}

export default Campaigns
